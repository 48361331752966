import "./App.css";

function Footer(props) {
  return (
    <div className="Footer">
      <div className="NavButtonBox">
        {!props.showHome && <button onClick={props.toggleHome}>BACK</button>}
        {props.showHome && (
          <>
            <button style={{ width: "100px" }} onClick={props.toggleInfo}>
              Info
            </button>
            <button onClick={props.toggleHoF}>Leaderboard</button>
            <button style={{ width: "100px" }} onClick={props.toggleSet}>
              Settings
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Footer;
