export const Circle = () => (
  <svg height="100%" width="100%" viewBox="0 0 100 100">
    <circle
      r="35"
      cx="50"
      cy="50"
      stroke="#f7323f"
      strokeWidth="2"
      fill="none"
    />
  </svg>
);

export const Marker = () => (
  <svg height="28px" width="28px" viewBox="0 0 198 198">
    <polygon points="100,10 40,198 190,78 10,78 160,198" fill="White" />
  </svg>
);
