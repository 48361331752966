import Webcam from "react-webcam";
import { useCallback, useEffect, useState } from "react";

function Set() {
  const [name, setName] = useState([]);
  const [camTest, setCamTest] = useState(null);
  const [devices, setDevices] = useState([]);

  const handleDevices = useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  const handleClick = () => {
    setCamTest(!camTest);
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    if (localStorage.getItem("userName")) {
      setName(localStorage.getItem("userName"));
    } else {
      setName("ANONYMOUS");
    }
  }, []);

  function clearCache() {
    localStorage.removeItem("userName");
    localStorage.removeItem("userMail");
  }

  return (
    <div className="Body">
      <h2>Settings</h2>
      <div className="text">
        User: {name} <br /> &nbsp;
      </div>
      <>
        <button
          onClick={() => {
            clearCache();
            setName("ANONYMOUS");
          }}
        >
          Clear cache
        </button>
        {false && (
          <div className="ToggleBox">
            <div className="text">Camera test: </div>
            <div className="toggle-slide" onClick={handleClick}>
              <div className={`switch ${camTest ? "slide " : ""}`}></div>
            </div>
          </div>
        )}
      </>
      {camTest &&
        devices.map((device, key) => (
          <>
            <div className="camera-container">
              <Webcam
                audio={false}
                mirrored={false}
                width={100 + "%"}
                height={100 + "%"}
                videoConstraints={{
                  deviceId: device.deviceId,
                  height: 720,
                  aspectRatio: 1.0,
                }}
              />
            </div>
            <div className="text">{device.label || `Device ${key + 1}`}</div>
          </>
        ))}
    </div>
  );
}

export default Set;
