// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-slide {
    margin: 0px 20px;
    border: 0px solid lightgrey;
    height: 22px;
    width: 42px;
    border-radius: 11px;
    background-color: lightgrey;
    position: relative;
    transition: all 200ms;
}
  
.switch {
  height: 20px;
  width: 20px;
  background-color: #c98c3c;
  border-radius: 999px;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: ease-in 200ms;
}

.slide {
  transition: ease-in 200ms;
  position: absolute;
  left: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Switchstyles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,2BAA2B;IAC3B,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,oBAAoB;EACpB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;AACX","sourcesContent":[".toggle-slide {\r\n    margin: 0px 20px;\r\n    border: 0px solid lightgrey;\r\n    height: 22px;\r\n    width: 42px;\r\n    border-radius: 11px;\r\n    background-color: lightgrey;\r\n    position: relative;\r\n    transition: all 200ms;\r\n}\r\n  \r\n.switch {\r\n  height: 20px;\r\n  width: 20px;\r\n  background-color: #c98c3c;\r\n  border-radius: 999px;\r\n  position: absolute;\r\n  top: 1px;\r\n  left: 1px;\r\n  transition: ease-in 200ms;\r\n}\r\n\r\n.slide {\r\n  transition: ease-in 200ms;\r\n  position: absolute;\r\n  left: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
