import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.js";

import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: "236fed90-7864-40e3-806d-244370110352",
    authority:
      "https://login.microsoftonline.com/9eab4bc7-132f-4754-89a8-6d2b001e6c43/",
    scopes: "api://misake/user_impersonation",
  },
};

const pca = new PublicClientApplication(configuration);

// Component
const AppProvider = () => (
  <MsalProvider instance={pca}>
    <App />
  </MsalProvider>
);

ReactDOM.render(<AppProvider />, document.getElementById("root"));
