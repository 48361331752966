import Example from "./example.png";

function Info() {
  return (
    <div className="Body">
      <h2>Info</h2>
      <div className="text" align="center">
        Die THP MiSAKE App wurde für die objektive Erkennung der Qualität und
        Eigenschaften von Milchschaum entwickelt. Eingereichte Bilder werden
        durch ein Machine Learning Modell verarbeitet, welches den Milchschaum
        extrahiert und eine Art von Fingerabdruck anhand dessen optischen
        Merkmale erstellt. Der Fingerabdruck wird benutzt, um die Ähnlichkeit zu
        bereits kategorisierten Milchschaumbildern zu messen und so eine Aussage
        über die Art des Schaums zu treffen. Da die Performance des trainierten
        Modells von der Anzahl und Vielfalt der Trainingsbilder abhängt, ist es
        unser Ziel, möglichst viele unterschiedliche Bilder mit der THP MiSAKE
        App zu sammeln. Mit anderen Worten, je mehr Milchschaumbilder mit der
        App eingereicht werden, desto zuverlässiger kann das Modell zukünftig
        Milchschaumbilder kategorisieren.
        <br />
        <br />
        Da wir von einer regen Nutzung der THP MiSAKE App profitieren, möchten
        wir die Nutzer der App auf spielerische Art und Weise animieren
        möglichst viele Milchschaumbilder einzureichen. Dazu haben wir ein
        Leaderboard in die App integriert und werden die drei Bestplatzierten
        mit einem Znüni belohnen. Um teilzunehmen, muss man lediglich beim
        Einreichen der Fotos sein Firmenkürzel angeben. Das Leaderboard ist
        öffentlich, d.h. jeder Thermoplaner mit Zugang zu THP Connect kann das
        Leaderboard einsehen. Diejenigen, die anonym bleiben möchten, können den
        Benutzernamen auf dem standardmässig vordefinierten 'ANONYMOUS' belassen
        oder dies im Nachhinein über die Einstellungen durch das Löschen des
        Caches resetten.
        <br />
        <br />
        Beim Benutzen der THP MiSAKE App sind folgende Regeln zu befolgen:
        <br />
        <ol align="left">
          <li>
            <b>Nur Fotos von Milchschaum / Kaffeeprodukten</b> einreichen: Alle
            Fotos werden in einer Daten Cloud abgelegt und sind für weitere
            Verwendungszwecke vorgesehen. Sie dürfen auf keinen Fall Gesichter,
            persönliche oder geheime Informationen enthalten. Wir wollen nur
            Milchschaum und Kaffeeprodukte kategorisieren können.
          </li>
          <br />
          <li>
            Nur Fotos von Kaffeeprodukten einreichen, aus denen noch nicht
            konsumiert wurde.
          </li>
          <br />
          <li>
            Uploads unter dem Namen 'ANONYMOUS' werden anonym gehalten und
            deshalb auch nicht im Gewinnspiel berücksichtigt.
          </li>
          <br />
          <li>
            Die App darf nur von Mitarbeitenden der Thermoplan AG benutzt
            werden.
          </li>
          <br />
          <li>
            Die rote Markierung in der Kameraansicht ist als Schablone für den
            Milchschaum und nicht für den Tassenrand gedacht.
          </li>
          <br />
          <li>
            Mehrmalige Uploads des gleichen Produkts sind unter folgenden
            Bedingungen erlaubt:
            <ul>
              <br />
              <li>
                Die Aufnahmen wurden mit einem unterschiedlichen Hintergrund
                aufgenommen.
              </li>
              <li>
                Die Aufnahmen wurden mit einer unterschiedlichen Belichtung
                aufgenommen.
              </li>
              <li>
                Dasselbe Produkt darf generell höchstens drei Mal eingereicht
                werden.
              </li>
              <li>Das Produkt ist nicht älter als zwei Minuten.</li>
            </ul>
          </li>
          <br />
          <li>
            Die Erwartung an die Performance der App ist anfangs etwas tiefer zu
            halten, da wir uns momentan in der Testphase befinden.
          </li>
          <br />
          <li>
            Eingereichte Fotos können stichprobenartig auf ihre Qualität
            untersucht werden.
          </li>
          <br />
          <li>
            Bei Fragen, Anregungen oder Kritik stehen Oliver Poole (<b>OPOO</b>)
            oder Patrick Koller (<b>PKOL</b>) vom <b>TIC</b> jederzeit zur
            Verfügung.
          </li>
          <br />
          Viel Spass!
        </ol>
        So wirds gemacht: <br /> &nbsp;
        <div className="camera-container">
          <img src={Example} width="100%" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Info;
