import "./App.css";
import Camera from "./Camera";
import HoF from "./HoF";
import Info from "./Info";
import Set from "./Settings";

function Body(props) {
  return (
    <div className="Body">
      {props.showHome && (
        <>
          <div className="text">
            <h2>Hi {props.name}</h2>
            Let MiSAKE (美釅) classify your Milkfoam.
          </div>
          {props.showHome && (
            <div className="GoButton" onClick={props.toggleCamera}>
              GO!
            </div>
          )}
        </>
      )}
      {props.showCamera && (
        <Camera getToken={props.getToken} mail={props.mail} />
      )}
      {props.showHoF && <HoF getToken={props.getToken} />}
      {props.showInfo && <Info />}
      {props.showSet && <Set />}
    </div>
  );
}

export default Body;
