const url_0 =
  "https://prod-13.northeurope.logic.azure.com:443/workflows/e49b1cc231234d9ea336a6e5bf58a638/triggers/manual/paths/invoke?api-version=2016-10-01";
const url_1 =
  "https://prod-49.northeurope.logic.azure.com:443/workflows/8fc63a0657b444228015c3ada90cf3b8/triggers/manual/paths/invoke?api-version=2016-10-01";
const url_2 =
  "https://prod-25.northeurope.logic.azure.com:443/workflows/050ca8ae7b6c4c9e8bf056d9c69e9da9/triggers/manual/paths/invoke?api-version=2016-10-01";
const url_3 =
  "https://prod-50.northeurope.logic.azure.com:443/workflows/ca034a6dc2754c98bb4e28e4dea1e27d/triggers/manual/paths/invoke?api-version=2016-10-01";

export async function getRanking(token, group) {
  return await fetch(url_0, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, Group: group },
  });
}

export async function successfulSubmit(name, token, mail) {
  //const query = "&name=" + name + "&mail=" + mail;
  return await fetch(url_1, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, User: name, Mail: mail },
  });
}

export async function modelApi(name, token, data = {}) {
  return await fetch(url_2, {
    method: "POST",
    headers: { Authorization: "Bearer " + token, User: name },
    body: JSON.stringify({ image: data, image_input_size: 1000 }),
  });
}

export async function modelApiDummy(name, token, data = {}) {
  return await fetch(url_3, {
    method: "POST",
    headers: { Authorization: "Bearer " + token, User: name },
    body: JSON.stringify({ image: data, image_input_size: 1000 }),
  });
}
