function JsonDataDisplay(props) {
  const Display1to3 = props.JsonData.slice(0, 3).map((info) => {
    return (
      <tr>
        <td>{info.rank}</td>
        <td>{info.userId}</td>
        <td align="right">{info.count}</td>
      </tr>
    );
  });

  const Display4up = props.JsonData.slice(3).map((info) => {
    return (
      <tr>
        <td>{info.rank}</td>
        <td>{info.userId}</td>
        <td align="right">{info.count}</td>
      </tr>
    );
  });

  return (
    <div>
      <table styles="width: 100%" cellPadding="10px">
        <thead align="left">
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Uploads</th>
          </tr>
        </thead>
        <tbody
          align="left"
          style={{
            color: "#c98c3c",
            fontWeight: "bold",
          }}
        >
          {Display1to3}
        </tbody>
        <tbody align="left">{Display4up}</tbody>
      </table>
    </div>
  );
}

export default JsonDataDisplay;
