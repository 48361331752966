import JsonDataDisplay from "./JsonTable";
import SegmentedControl from "./SegmentedControl";
import { getRanking } from "./Api";
import { useRef, useEffect, useState } from "react";

function HoF(props) {
  const [hof, setHof] = useState([]);
  const [group, setGroup] = useState(0);

  useEffect(() => {
    apiCall();
  }, [group]);

  async function apiCall() {
    const response = await props
      .getToken()
      .then((token) => getRanking(token, group));
    if (!response.ok) {
      setHof(
        "Endpoint ERROR: " + response.status + " (" + response.statusText + ")"
      );
    } else {
      response.json().then((data) => {
        setHof(data);
      });
    }
  }

  return (
    <div className="Body">
      <h2>Leaderboard</h2>
      <JsonDataDisplay JsonData={hof} />
    </div>
  );
}

//<div className="Body">
//  <h2>Leaderboard</h2>
//  <SegmentedControl
//    name="groups"
//    callback={(val) => setGroup(val)}
//    controlRef={useRef()}
//    segments={[
//      {
//        label: "All",
//        value: 0,
//        ref: useRef(),
//      },
//      //          {
//      //            label: "Group 1",
//      //            value: 1,
//      //            ref: useRef(),
//      //          },
//      //          {
//      //            label: "Group 2",
//      //            value: 2,
//      //            ref: useRef(),
//      //          },
//      //          {
//      //            label: "Group 3",
//      //            value: 3,
//      //            ref: useRef(),
//      //          },
//      {
//        label: "R&D",
//        value: -1,
//        ref: useRef(),
//      },
//    ]}
//  />
//  <JsonDataDisplay JsonData={hof} />
//</div>;

export default HoF;
