import "./App.css";
import { ReactComponent as Logo } from "./logo.svg";

function Header() {
  return (
    <div className="Header">
      <Logo />
    </div>
  );
}

export default Header;
