import "./Switchstyles.css";
import Computer from "./coffee.gif";
import Map from "./map.png";
import Webcam from "react-webcam";
import { successfulSubmit, modelApi, modelApiDummy } from "./Api";
import { useCallback, useEffect, useRef, useState } from "react";
import { Circle, Marker } from "./Svg";
import { TextField } from "@mui/material";

function Camera(props) {
  const [img, setImg] = useState(null);
  const [name, setName] = useState([]);
  const [description, setDescription] = useState(null);

  const [feedback, setFeedback] = useState(null);
  const [detected, setDetected] = useState(null);

  const [d_csa, setCsa] = useState(null);
  const [d_cta, setCta] = useState(null);
  const [d_hsa, setHsa] = useState(null);
  const [d_hst, setHst] = useState(null);
  const [d_hta, setHta] = useState(null);
  const [d_htt, setHtt] = useState(null);

  const [_res, setRes] = useState(null);
  const [_sub, setSub] = useState(null);

  const [dummy, setDummy] = useState(null);

  const [hotFoam, setHotFoam] = useState(false);
  const [toweringFoam, setToweringFoam] = useState(false);

  const [x, setX] = useState(null);
  const [y, setY] = useState(null);

  const [xCoord, setXCoord] = useState(null);
  const [yCoord, setYCoord] = useState(null);

  const x_min = -1.5;
  const x_max = 13;
  const y_min = -1.5;
  const y_max = 7.1;

  useEffect(() => {
    if (localStorage.getItem("userName") == null) {
      setName("ANONYMOUS");
      localStorage.setItem("userMail", "");
    } else {
      setName(localStorage.getItem("userName"));
      localStorage.setItem("userMail", props.mail);
    }
  }, [props.mail]);

  function saveName() {
    if (name !== "ANONYMOUS" && localStorage.getItem("userName") == null) {
      localStorage.setItem("userName", name);
      localStorage.setItem("userMail", props.mail);
    }
  }

  useEffect(() => {
    function handleResize() {
      // Update the state or perform any other actions when the
      // browser is resized
      setCoord(x, y);
    }

    // Attach the event listener to the window object
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [x, y]);

  const handleDummy = () => {
    setDummy(!dummy);
  };

  const handleHotFoam = () => {
    setHotFoam(!hotFoam);
  };

  const handleToweringFoam = () => {
    setToweringFoam(!toweringFoam);
  };

  function setCoord(xx, yy) {
    if (window.innerWidth > 720) {
      setXCoord((720 / (x_max - x_min)) * (xx - (x_max + x_min) / 2) * 0.975);
      setYCoord(-(720 / (y_max - y_min)) * (yy - y_max) * 0.96);
    } else {
      setXCoord(
        (window.innerWidth / (x_max - x_min)) *
          (xx - (x_max + x_min) / 2) *
          0.975
      );
      setYCoord(-(window.innerWidth / (y_max - y_min)) * (yy - y_max) * 0.96);
    }
    setX(xx);
    setY(yy);
  }

  var markerStyle = {
    top: yCoord + "px",
    left: xCoord + "px",
  };

  async function Misake(img) {
    setSub(!_sub);
    var response = null;
    if (dummy) {
      response = await props
        .getToken()
        .then((token) =>
          modelApiDummy(name, token, img.replace("data:image/png;base64,", ""))
        );
    } else {
      response = await props
        .getToken()
        .then((token) =>
          modelApi(name, token, img.replace("data:image/png;base64,", ""))
        );
    }
    if (!response.ok) {
      setDescription(
        "Endpoint ERROR: " + response.status + " (" + response.statusText + ")"
      );
    } else {
      const res = await response.json().then((res) => {
        return res;
      });
      setDescription(calcFoam(res));
    }
    setSub(false);
    setRes(true);
  }

  function calcFoam(res) {
    if (res.appOk) {
      if (res.OK) {
        props.getToken().then((token) => {
          !dummy &&
            successfulSubmit(name, token, localStorage.getItem("userMail"));
        });
        try {
          setCoord(res.x, res.y);
          var comment = `Thank you for the submission. The star in the map indicates your product type, it was classified as: ${String(
            res.type
          )} with a probability of ${String(Math.round(res.probs * 100))}%.`;
          setDetected(res.type);
          if (res.d_cold_smooth_air !== "No Feedback") {
            setFeedback(true);
            setCsa(res.d_cold_smooth_air);
            setCta(res.d_cold_towering_air);
            setHsa(res.d_hot_smooth_air);
            setHst(res.d_hot_smooth_temp);
            setHta(res.d_hot_towering_air);
            setHtt(res.d_hot_towering_temp);
          }
          return res.type;
        } catch {
          return res;
        }
      } else {
        setCoord(-99, -99);
        return "No beverage detected!";
      }
    } else {
      return res.message;
    }
  }

  const webcamRef = useRef(null);

  const videoConstraints = {
    height: 720,
    aspectRatio: 1.0,
    facingMode: "environment",
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
  }, [webcamRef]);

  return (
    <div className="Body">
      <div className="Camera">
        {img == null && (
          <>
            <div className="text">
              Make sure to align the milk foam with the red circle for an
              optimal result. <br /> &nbsp;
            </div>
            <div className="camera-container">
              <Webcam
                audio={false}
                mirrored={false}
                width={100 + "%"}
                height={100 + "%"}
                ref={webcamRef}
                forceScreenshotSourceSize
                screenshotFormat="image/png"
                videoConstraints={videoConstraints}
              />
              <div className="camera-overlay">{Circle()}</div>
            </div>
            <div className="ButtonBox">
              <button onClick={capture}>Capture Photo</button>
            </div>
          </>
        )}
        {img != null && (
          <>
            {!_sub && !_res && (
              <>
                <div className="text">
                  Make sure to align the milk foam with the red circle for an
                  optimal result. <br /> &nbsp;
                </div>
                <div className="camera-container">
                  <img src={img} width="100%" alt="" />
                  <div className="camera-overlay">{Circle()}</div>
                </div>

                {!localStorage.getItem("userName") && (
                  <TextField
                    InputLabelProps={{
                      sx: {
                        color: "lightgray",
                      },
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "lightgray",
                      },
                    }}
                    inputProps={{
                      sx: {
                        color: "lightgray",
                      },
                    }}
                    value={name}
                    margin="normal"
                    label="THP Short Name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                )}
                {localStorage.getItem("userName") && (
                  <div className="text">
                    <br /> &nbsp; User: {localStorage.getItem("userName")}
                  </div>
                )}
                <div className="ButtonBox">
                  <button onClick={() => setImg(null)}>Retake</button>
                  <button
                    onClick={() => {
                      saveName();
                      Misake(img);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </>
        )}
        {_sub && (
          <>
            <div className="text">
              The server will be busy for about 10 seconds... <br /> &nbsp;
            </div>
            <div className="camera-container">
              <img src={Computer} width="100%" alt="" />
            </div>
          </>
        )}
        {_res && false && x !== -99 && (
          <div className="camera-container">
            <img src={Map} width="100%" alt="" />
            <div className="camera-overlay" style={markerStyle}>
              {Marker()}
            </div>
          </div>
        )}
        {_res && (
          <>
            <br />
            <div
              className="text"
              style={{
                color: "#c98c3c",
                fontWeight: "bold",
              }}
            >
              <h2>{description}</h2>
            </div>
            <div
              className="text"
              style={{
                color: "lightgrey",
              }}
            >
              by MiSAKE
            </div>
            {feedback && detected && (
              <>
                <div className="text">
                  <h4>
                    <hr />
                    <br />
                    Choose your target foam-type for further guidance:
                  </h4>
                </div>
                <div className="ToggleBox">
                  {hotFoam && (
                    <div
                      className="text"
                      style={{ color: "lightgrey", width: "100px" }}
                    >
                      COLD
                    </div>
                  )}
                  {!hotFoam && (
                    <div
                      className="text"
                      style={{
                        color: "#c98c3c",
                        fontWeight: "bold",
                        width: "100px",
                      }}
                    >
                      COLD
                    </div>
                  )}
                  <div className="toggle-slide" onClick={handleHotFoam}>
                    <div className={`switch ${hotFoam ? "slide " : ""}`}></div>
                  </div>
                  {hotFoam && (
                    <div
                      className="text"
                      style={{
                        color: "#c98c3c",
                        fontWeight: "bold",
                        width: "100px",
                      }}
                    >
                      HOT
                    </div>
                  )}
                  {!hotFoam && (
                    <div
                      className="text"
                      style={{ color: "lightgrey", width: "100px" }}
                    >
                      HOT
                    </div>
                  )}
                </div>

                <div className="ToggleBox">
                  {toweringFoam && (
                    <div
                      className="text"
                      style={{ color: "lightgrey", width: "100px" }}
                    >
                      SILKY
                      <br />
                      (wet)
                    </div>
                  )}
                  {!toweringFoam && (
                    <div
                      className="text"
                      style={{
                        color: "#c98c3c",
                        fontWeight: "bold",
                        width: "100px",
                      }}
                    >
                      SILKY
                      <br />
                      (wet)
                    </div>
                  )}
                  <div className="toggle-slide" onClick={handleToweringFoam}>
                    <div
                      className={`switch ${toweringFoam ? "slide " : ""}`}
                    ></div>
                  </div>
                  {toweringFoam && (
                    <div
                      className="text"
                      style={{
                        color: "#c98c3c",
                        fontWeight: "bold",
                        width: "100px",
                      }}
                    >
                      TOWERING
                      <br />
                      (dry)
                    </div>
                  )}
                  {!toweringFoam && (
                    <div
                      className="text"
                      style={{ color: "lightgrey", width: "100px" }}
                    >
                      TOWERING
                      <br />
                      (dry)
                    </div>
                  )}
                </div>
                {hotFoam && toweringFoam && (
                  <>
                    <br />
                    <div className="text">
                      If you are not happy with the quality of your milk foam,
                      MiSAKE recommends to adjust the system parameters as
                      follows to achieve a hot towering foam.
                    </div>
                    <br />
                    1. {d_hta} the air amount.
                    <br />
                    <br />
                    2. {d_htt} the temperature.
                  </>
                )}
                {hotFoam && !toweringFoam && (
                  <>
                    <br />
                    <div className="text">
                      If you are not happy with the quality of your milk foam,
                      MiSAKE recommends to adjust the system parameters as
                      follows to achieve a hot silky foam.
                    </div>
                    <br />
                    1. {d_hsa} the air amount.
                    <br />
                    <br />
                    2. {d_hst} the temperature.
                  </>
                )}
                {!hotFoam && toweringFoam && (
                  <>
                    <br />
                    <div className="text">
                      If you are not happy with the quality of your milk foam,
                      MiSAKE recommends to adjust the system parameters as
                      follows to achieve a cold towering foam.
                    </div>
                    <br />
                    1. {d_cta} the air amount.
                  </>
                )}
                {!hotFoam && !toweringFoam && (
                  <>
                    <br />
                    <div className="text">
                      If you are not happy with the quality of your milk foam,
                      MiSAKE recommends to adjust the system parameters as
                      follows to achieve a cold silky foam.
                    </div>
                    <br />
                    1. {d_csa} the air amount.
                  </>
                )}
                {description == "Bubbly Foam" && hotFoam && toweringFoam && (
                  <>
                    <br />
                    <br />
                    <div
                      className="text"
                      style={{
                        color: "#FFD300",
                        width: "100px",
                        fontWeight: "bold",
                      }}
                    >
                      CAUTION:
                    </div>
                    <div className="text">
                      Increase the air amount if you wish to have a milk foam
                      close to 50°C instead.
                    </div>
                  </>
                )}
                <div className="text">
                  <h4>
                    <hr />
                    <br />
                    General Recommendations BW4:
                  </h4>
                  Don't go beyond 70°C in foam-type settings.
                </div>
              </>
            )}
            {!feedback && detected && (
              <>
                <div className="text">
                  <br />
                  <hr />
                  <br />
                  According to the submitted photo, we recommend not to change
                  your milk-foam system-parameters.
                </div>
              </>
            )}
          </>
        )}
        {!_sub &&
          !_res &&
          (props.mail === "opoole@thermoplan.ch" ||
            props.mail === "pkoller@thermoplan.ch") && (
            <div className="ToggleBox">
              <div className="text">use dummy: </div>
              <div className="toggle-slide" onClick={handleDummy}>
                <div className={`switch ${dummy ? "slide " : ""}`}></div>
              </div>
              {dummy && <div className="text"> ON</div>}
              {!dummy && <div className="text"> OFF</div>}
            </div>
          )}
      </div>
    </div>
  );
}

export default Camera;
