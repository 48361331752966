import "./App.css";
import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header";
import React from "react";
import { useState, useEffect } from "react";
import {
  useAccount,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";

function App() {
  const [mail, setMail] = useState([]);
  const [name, setName] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [showHome, setShowHome] = useState(true);
  const [showHoF, setShowHoF] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showSet, setShowSet] = useState(false);

  useMsalAuthentication("redirect");

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (account) {
      setName(account.name.split(" ")[0]);
      setMail(account.username);
    }
  }, [account]);

  async function getToken() {
    if (account) {
      const accessToken = await instance
        .acquireTokenSilent({
          scopes: ["api://misake/user_impersonation"],
          account: account,
        })
        .then((response) => {
          if (response) {
            return response.accessToken;
          }
        });
      return accessToken;
    }
  }

  const toggleHome = () => {
    setShowHome(true);
    setShowCamera(false);
    setShowHoF(false);
    setShowInfo(false);
    setShowSet(false);
  };

  const toggleCamera = () => {
    setShowHome(false);
    setShowCamera(true);
    setShowHoF(false);
    setShowInfo(false);
    setShowSet(false);
  };

  const toggleHoF = () => {
    setShowHome(false);
    setShowCamera(false);
    setShowHoF(true);
    setShowInfo(false);
    setShowSet(false);
  };

  const toggleInfo = () => {
    setShowHome(false);
    setShowCamera(false);
    setShowHoF(false);
    setShowInfo(true);
    setShowSet(false);
  };

  const toggleSet = () => {
    setShowHome(false);
    setShowCamera(false);
    setShowHoF(false);
    setShowInfo(false);
    setShowSet(true);
  };

  if (accounts.length > 0) {
    return (
      <>
        <React.Fragment>
          {isAuthenticated && (
            <div className="App">
              <Header />
              <Body
                mail={mail}
                name={name}
                showHome={showHome}
                showHoF={showHoF}
                showInfo={showInfo}
                showSet={showSet}
                showCamera={showCamera}
                toggleCamera={toggleCamera}
                getToken={getToken}
              />
              <Footer
                showHome={showHome}
                toggleHome={toggleHome}
                toggleHoF={toggleHoF}
                toggleInfo={toggleInfo}
                toggleSet={toggleSet}
              />
            </div>
          )}
        </React.Fragment>
      </>
    );
  } /*else if (inProgress === "login") {
    return <span>Login is currently in progress!</span>;
  } else {
    return <span>There are currently no users signed in!</span>;
  }*/
}

export default App;
